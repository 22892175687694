import Image from "next/image";
import { useTranslation } from "react-i18next";

import LaptopHomepageImg from "assets/2.png";
import LaptopApplicationDetailPage from "assets/3.png";

const WhatWeOffer = () => {
  const { t } = useTranslation();

  return (
    <main>
      <div className="mx-auto flex max-w-screen-xl flex-col gap-8 px-10 pb-10 md:pb-36">
        <section className="my-8 flex flex-col md:my-0 md:flex-row md:items-center md:gap-20">
          <Image
            src={LaptopHomepageImg}
            alt="Laptop that shows Students page"
            className="mb-10 h-auto w-full object-contain md:mb-0 md:w-1/2"
            priority
          />
          <div className="flex w-full flex-col gap-6 text-secondary-1000 md:w-1/2 md:pt-10 md:text-lg">
            <p className="text-start text-sm font-bold tracking-widest text-primary-500">
              {t("whatWeOffer.title")}
            </p>
            <p className="text-2xl font-semibold md:text-4xl">
              {t("whatWeOffer.firstSection.title")}
            </p>
            <p>{t("whatWeOffer.firstSection.description")}</p>
            <p className="text-2xl font-semibold md:text-4xl">
              {t("whatWeOffer.secondSection.title")}
            </p>
            <p>{t("whatWeOffer.secondSection.description")}</p>
            <p className="text-2xl font-semibold md:text-4xl">
              {t("whatWeOffer.thirdSection.title")}
            </p>
            <p>{t("whatWeOffer.thirdSection.description")}</p>
          </div>
        </section>
        <section className="relative flex flex-col-reverse md:flex-row md:gap-20">
          <div className="flex w-full flex-col gap-8 pt-6 text-secondary-1000  md:w-1/2 md:text-lg">
            <p className="mt-8 text-2xl font-semibold md:mt-0 md:text-4xl">
              {t("whatWeOffer.fourthSection.title")}
            </p>
            <p>{t("whatWeOffer.fourthSection.description")}</p>
            <p className="text-2xl font-semibold md:text-4xl">
              {t("whatWeOffer.fifthSection.title")}
            </p>
            <p>{t("whatWeOffer.fifthSection.description")}</p>
            <p className="text-2xl font-semibold md:text-4xl">
              {t("whatWeOffer.sixthSection.title")}
            </p>
            <p>{t("whatWeOffer.sixthSection.description")}</p>
          </div>
          <Image
            src={LaptopApplicationDetailPage}
            alt="Laptop that shows Application Detail page"
            className="h-auto w-full object-contain md:w-1/2"
          />
        </section>
      </div>
    </main>
  );
};

export default WhatWeOffer;
