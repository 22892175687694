import Image from "next/image";
import { useTranslation } from "react-i18next";

import FirstGif from "assets/Register.png";
import SecondGif from "assets/Email Activate.png";
import ThirdGif from "assets/Business info - onboarding.png";
import FourthGif from "assets/Receive contract.png";
import FifthGif from "assets/contract signed.png";
import SixthGif from "assets/All done.png";

const Card = ({ cardNumber, title, description, subtitle, image, hidden }) => (
  <div
    className={` relative flex flex-col items-center justify-center gap-4 rounded-lg bg-white p-6 pt-10 shadow-2xl lg:flex-row lg:pt-6 ${
      hidden ? "block lg:invisible" : ""
    }`}
  >
    <div className="left-30 absolute -top-5 h-12 w-12 rounded-full bg-primary-500 pt-2.5 text-center text-2xl text-white lg:-top-5 lg:left-10 lg:h-12 lg:w-12 lg:pr-0.5 lg:pt-1.5 lg:text-3xl">
      {cardNumber}
    </div>
    <Image src={image} alt={title} className="h-60 w-60 lg:h-40 lg:w-40" />
    <div className="flex flex-col gap-2 text-center lg:text-left">
      <p className="text-lg font-semibold">{title}</p>
      <p className="text-secondary-600">{description}</p>
      {subtitle && <p className="text-xs text-secondary-600">{subtitle}</p>}
    </div>
  </div>
);

const HowToStart = () => {
  const { t } = useTranslation();

  return (
    <main className=" bg-whatWeOfferBackground">
      <section className="mx-auto flex max-w-screen-xl flex-col gap-20 px-10 py-10 md:py-24 ">
        <h3 className="text-center text-4xl font-semibold text-primary-500 md:text-6xl">
          {t("howToStart.title")}
        </h3>
        <div className="flex ">
          <div className="flex flex-col justify-center space-y-12 border-dotted border-black lg:w-1/2 lg:space-y-6 lg:border-r-2 lg:pr-8">
            <Card
              cardNumber="1"
              title={t("howToStart.firstSection.title")}
              description={t("howToStart.firstSection.description")}
              image={FirstGif}
            />
            <Card
              cardNumber="2"
              title={t("howToStart.secondSection.title")}
              description={t("howToStart.secondSection.description")}
              subtitle={t("howToStart.secondSection.subtitle")}
              image={SecondGif}
              hidden
            />
            <Card
              cardNumber="3"
              title={t("howToStart.thirdSection.title")}
              description={t("howToStart.thirdSection.description")}
              image={ThirdGif}
            />
            <Card
              cardNumber="4"
              title={t("howToStart.fourthSection.title")}
              description={t("howToStart.fourthSection.description")}
              image={FourthGif}
              hidden
            />
            <Card
              cardNumber="5"
              title={t("howToStart.fifthSection.title")}
              description={t("howToStart.fifthSection.description")}
              image={FifthGif}
            />
            <Card
              cardNumber="6"
              title={t("howToStart.sixthSection.title")}
              description={t("howToStart.sixthSection.description")}
              image={SixthGif}
              hidden
            />
          </div>
          <div className="hidden w-1/2 flex-col space-y-6 pl-8 lg:flex">
            <Card
              cardNumber="1"
              title={t("howToStart.firstSection.title")}
              description={t("howToStart.firstSection.description")}
              image={FirstGif}
              hidden
            />
            <Card
              cardNumber="2"
              title={t("howToStart.secondSection.title")}
              description={t("howToStart.secondSection.description")}
              subtitle={t("howToStart.secondSection.subtitle")}
              image={SecondGif}
            />
            <Card
              cardNumber="3"
              title={t("howToStart.thirdSection.title")}
              description={t("howToStart.thirdSection.description")}
              image={ThirdGif}
              hidden
            />
            <Card
              cardNumber="4"
              title={t("howToStart.fourthSection.title")}
              description={t("howToStart.fourthSection.description")}
              image={FourthGif}
            />
            <Card
              cardNumber="5"
              title={t("howToStart.fifthSection.title")}
              description={t("howToStart.fifthSection.description")}
              image={FifthGif}
              hidden
            />
            <Card
              cardNumber="6"
              title={t("howToStart.sixthSection.title")}
              description={t("howToStart.sixthSection.description")}
              image={SixthGif}
            />
          </div>
        </div>
      </section>
    </main>
  );
};

export default HowToStart;
