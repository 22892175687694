/* eslint-disable react/display-name */
import React from "react";
import classNames from "classnames";

const Input = React.forwardRef(
  (
    {
      label,
      underLabel,
      hideLabel,
      type,
      name,
      id,
      step,
      pattern,
      placeholder,
      shadowInner,
      disabled,
      icon,
      className,
      onChange,
      value,
      error,
      substring,
      min,
      max,
    },
    ref
  ) => (
    <div className={classNames("flex flex-col ", className)}>
      {label && (
        <label
          htmlFor={id}
          className="flex-start mb-2 flex items-center font-semibold"
        >
          <p className={` ${hideLabel ? "invisible" : ""}  `}>{label}</p>{" "}
          {substring && (
            <span className="ml-1 font-semibold text-primary-500">
              {substring}
            </span>
          )}
        </label>
      )}
      <div className="relative flex flex-col gap-2">
        <input
          type={type}
          min={min}
          max={max}
          id={id}
          name={name}
          step={step}
          value={value}
          disabled={disabled}
          pattern={pattern}
          onChange={onChange}
          onWheel={(e) => e.target.blur()}
          placeholder={placeholder}
          className={classNames(
            "border-light w-full rounded-md border-2 py-2 px-2",
            {
              "border-none bg-secondary-50 shadow-inner": shadowInner,
              "pl-8": icon,
            }
          )}
          ref={ref}
          required
        />
        {underLabel && (
          <label htmlFor={id} className="flex-start ml-1 flex items-center">
            <p className="text-sm ">{underLabel}</p>
          </label>
        )}
      </div>
      {error && <p className="font-semibold text-primary-500">{error}</p>}
    </div>
  )
);

export default Input;
