import Image from "next/image";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import WhoAreWeImg from "assets/who-we-are.png";

const WhoAreWe = () => {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <>
      <section className="flex bg-primary-500 py-9 px-6 font-semibold text-white sm:py-16">
        <div className="mx-auto max-w-screen-xl">
          <h2 className="text-center text-2xl sm:text-4xl">
            {router.locale === "en" ? (
              <>
                "An ed-tech company that intermediates between the schools and
                recruitment agencies for more businesslike operations."
              </>
            ) : (
              <>
                "Une entreprise ed-tech qui fait l'intermédiaire entre les
                écoles et les cabinets de recrutement pour des opérations plus
                business.""
              </>
            )}
          </h2>
        </div>
      </section>
      <section className=" mx-auto my-20 flex min-h-[85vh] max-w-screen-xl flex-col-reverse gap-8 p-4 md:p-0 lg:flex-row">
        <div className="mx-auto flex flex-col justify-center gap-6 text-center md:text-start lg:w-2/5">
          <h1 className="text-center text-4xl font-semibold text-secondary-1000 md:text-6xl lg:text-start">
            {t("companyDescription.title")}
          </h1>
          <div className="space-y-4 text-base text-black sm:text-lg">
            <p>{t("companyDescription.firstDescription")}</p>
            <p>{t("companyDescription.secondDescription")}</p>
            <p className="font-bold">{t("companyDescription.subtitle")}</p>
          </div>
        </div>
        <Image
          src={WhoAreWeImg}
          alt="Submitapp's targetted regions on map"
          className=" object-contain lg:w-3/5"
        />
      </section>
    </>
  );
};

export default WhoAreWe;
