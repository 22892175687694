import { useState, useRef } from "react";
import Image from "next/image";
import { FaUsers } from "react-icons/fa";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";

import CityBackground from "assets/submitapp-city-background.jpg";
import Input from "common/components/inputs/input";
import Textarea from "common/components/inputs/textarea";
import Button from "common/components/buttons/button";
import AlertBanner from "common/components/alertBanner";
import Link from "next/link";

const ContactUs = () => {
  const [messageSent, setMessageSent] = useState(null);
  const formRef = useRef();
  const { t } = useTranslation();
  const router = useRouter();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData(formRef.current);
    const body = {
      companyName: formdata.get("companyName"),
      fullName: formdata.get("firstName") + " " + formdata.get("lastName"),
      phone: formdata.get("phone"),
      email: formdata.get("email"),
      message: formdata.get("message"),
    };

    try {
      await axios
        .post("api/contact-us-admin", body)
        .then(async () => await axios.post("api/contact-us-visitor", body))
        .then(() => {
          formRef.current.reset();
          setMessageSent(true);
        });
    } catch (err) {
      setMessageSent(false);
    }
  };

  return (
    <section className="text-black" id="contact-us">
      <div className="mx-auto flex flex-col gap-20 py-6  px-10 md:max-w-screen-xl">
        <div className="flex w-full flex-col gap-10 pt-10 text-left leading-9 ">
          <div className="flex justify-center gap-2">
            <div className=" my-auto flex h-10 w-10 items-center justify-center rounded-lg bg-primary-500 text-white  duration-75 ease-in-out hover:text-black">
              <FaUsers size={30} />
            </div>
            <p className="font-semibold uppercase tracking-wider">
              {t("contactUs.header")}
            </p>
          </div>
          <p className="text-center text-4xl font-semibold text-secondary-1000 md:text-6xl ">
            {t("contactUs.title")}
          </p>
          <div className="space-y-2 text-center text-base  text-black sm:text-xl">
            <p>
              {t("contactUs.description")}
              {router.locale === "en" ? (
                <>
                  You can also request a video call to better understand how to
                  use our platform. Please navigate to the{" "}
                  <Link
                    className="cursor-pointer font-bold text-primary-400 "
                    href="/book-a-session"
                  >
                    "Book a Session"
                  </Link>{" "}
                  page and complete the booking process to set up a call with
                  the SubmitApp team.
                </>
              ) : (
                <>
                  Vous pouvez également demander un appel vidéo pour mieux
                  comprendre la plateforme. Veuillez accéder à la page{" "}
                  <Link
                    className="cursor-pointer font-bold text-primary-400"
                    href="/book-a-session"
                  >
                    Réserver une session
                  </Link>{" "}
                  et terminer le processus de réservation pour organiser un
                  appel avec l'équipe SubmitApp
                </>
              )}
            </p>
          </div>
        </div>
        <form
          ref={formRef}
          className="mx-auto mb-10 flex w-full max-w-screen-sm flex-col gap-2 text-black"
          onSubmit={handleSubmit}
        >
          <Input
            id="companyName"
            type="text"
            name="companyName"
            label={t("contactUs.companyName")}
            substring="*"
            className="mb-4"
          />
          <div className="mb-4 flex justify-between gap-6">
            <Input
              id="firstName"
              type="text"
              name="firstName"
              label={t("contactUs.name")}
              underLabel={t("contactUs.firstName")}
              substring="*"
              className="w-full"
            />
            <Input
              id="lastName"
              type="text"
              name="lastName"
              label="a"
              underLabel={t("contactUs.lastName")}
              hideLabel
              className="w-full"
            />
          </div>
          <Input
            id="phone"
            type="phone"
            label={t("contactUs.phone")}
            name="phone"
            className="mb-4"
          />
          <Input
            id="email"
            type="email"
            label={t("contactUs.email")}
            name="email"
            className="mb-4"
          />
          <Textarea
            id="message"
            type="text"
            name="message"
            label={t("contactUs.message")}
            substring="*"
            className="mb-4 text-black"
          />
          <Button type="primary" className=" px-10 py-3.5 md:mb-0">
            {t("contactUs.submit")}
          </Button>
        </form>
      </div>
      {messageSent && (
        <div className="fixed bottom-20  left-1/2 z-40 -translate-x-2/4 text-center">
          <AlertBanner
            type="success"
            onClose={() => {
              setMessageSent(null);
            }}
            onClickOutside={() => {
              setMessageSent(null);
            }}
          />
        </div>
      )}
      {messageSent === false && (
        <div className="fixed bottom-20  left-1/2 z-40 -translate-x-2/4 text-center">
          <AlertBanner
            type="error"
            onClose={() => {
              setMessageSent(null);
            }}
            onClickOutside={() => {
              setMessageSent(null);
            }}
          />
        </div>
      )}
    </section>
  );
};

export default ContactUs;
