import Image from "next/image";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import LaptopLoginScreen from "assets/1.png";
import BGImage from "assets/homepage-bg.png";
import LinkButton from "common/components/buttons/linkButton";

const Hero = () => {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <section className="relative px-10 pb-8 md:py-0">
      <div className=" mx-auto flex min-h-[85vh] max-w-screen-xl flex-col-reverse md:flex-row md:items-center md:justify-center ">
        <div className="z-10 flex w-full flex-col gap-4 text-center md:w-1/2 md:text-left">
          <h1 className="mt-10 text-3xl font-extrabold uppercase sm:text-5xl md:mt-0 lg:text-6xl ">
            {router.locale === "en" ? (
              <>
                <span className="text-primary-500"> Fast and Easy</span>{" "}
                Application Gateway for Student Recruitment
              </>
            ) : (
              <>
                Passerelle d'application
                <span className="text-primary-500"> rapide et facile</span> pour
                le recrutement d'étudiants
              </>
            )}
          </h1>
          <p className="mb-3 text-base text-black sm:text-xl">
            {t("homepageHero.description")}
          </p>
          <div className="flex flex-col gap-6 md:flex-row">
            <LinkButton
              href="/book-a-session"
              type="primary"
              className="px-8 py-2.5"
            >
              {t("common.bookASession")}
            </LinkButton>
            <LinkButton
              href="https://portal.submitapp.io/agency/register"
              type="secondary"
              className="px-8 py-2.5"
              external
            >
              {t("homepageHero.button")}
            </LinkButton>
          </div>
        </div>
        <Image
          src={LaptopLoginScreen}
          alt="Laptop that shows Portal Login Screen"
          className="z-10 h-auto w-full object-contain md:w-1/2"
          priority
        />
      </div>
      <div className="-z-10">
        <Image
          src={BGImage}
          alt="BG Image"
          fill
          style={{ objectFit: "cover" }}
          priority
        />
      </div>
    </section>
  );
};

export default Hero;
