import { useEffect, useRef } from "react";
import { ImCross } from "react-icons/im";
import classNames from "classnames";

const types = {
  success: "mb-4 rounded-lg bg-green-700 p-4  text-lg  text-white",
  error: "mb-4 rounded-lg bg-red-700 p-4  text-lg  text-white",
};

const AlertBanner = ({ type, onClose, onClickOutside }) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <div
      ref={ref}
      className={classNames(types[type], "flex items-center")}
      role="alert"
    >
      {type === "success" ? (
        <>
          <span className="font-semibold">Email Sent! </span> &nbsp; We will get
          back to you shortly.
        </>
      ) : (
        <>
          <span className="font-semibold">Email couldn&apos;t be sent!</span>{" "}
          Please try again!
        </>
      )}
      <ImCross
        className="ml-4 cursor-pointer"
        size={17}
        onClick={() => onClose()}
      />
    </div>
  );
};

export default AlertBanner;
