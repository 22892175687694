/* eslint-disable react/display-name */
import React from "react";
import classNames from "classnames";

const Textarea = React.forwardRef(
  (
    {
      label,
      type,
      name,
      id,
      placeholder,
      rows,
      cols,
      className,
      error,
      substring,
      onChange,
    },
    ref
  ) => (
    <div className={classNames("flex flex-col", className)}>
      {label && (
        <label htmlFor={id} className="mb-2 flex items-center font-semibold ">
          <p>{label}</p>{" "}
          {substring && (
            <span className="ml-1 font-semibold text-primary-500">
              {substring}
            </span>
          )}
        </label>
      )}
      <div className="relative flex">
        <textarea
          type={type}
          id={id}
          name={name}
          placeholder={placeholder}
          rows={rows}
          cols={cols}
          className={classNames(
            "border-light h-40 w-full resize-none appearance-none rounded-md border-2 p-2"
          )}
          ref={ref}
          required
          onChange={onChange}
        />
      </div>
      {error && <p className="font-semibold text-primary-500">{error}</p>}
    </div>
  )
);

export default Textarea;
