import Head from "next/head";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "react-i18next";

import Hero from "views/homepage/views/hero.view";
import WhatWeOffer from "views/homepage/views/whatWeOffer.view";
import WhereDoWeTarget from "views/homepage/views/whereDoWeTarget.view";
import ContactUs from "views/common/contactUs";
import WhoAreWe from "views/homepage/views/whoAreWe";
import HowToStart from "views/homepage/views/howToStart";

export default function Home() {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>{t("common.home")} - Submitapp</title>
        <meta name="description" content="Generated by create next app" />
      </Head>
      <main>
        <Hero />
        <WhoAreWe />
        <HowToStart />
        <WhatWeOffer />
        <WhereDoWeTarget />
        <ContactUs />
      </main>
    </>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}
