import classNames from "classnames";

const types = {
  primary: "text-white bg-primary-500 hover:bg-primary-600",
  secondary: "text-white bg-secondary-700 hover:bg-secondary-800",
  search: "text-white bg-secondary-700 hover:bg-secondary-800",
  underline:
    "text-primary-500 hover:underline bg-secondary-50 hover:bg-secondary-200",
  hollow:
    "text-primary-500 border border-solid border-red-700  hover:underline hover:bg-primary-600 hover:text-white",
};

const Button = ({ children, type, onClick, status, className }) => (
  <button
    type="submit"
    onClick={onClick}
    className={classNames(
      "rounded-md p-2 px-4 font-bold ",
      types[type],
      className,
      {
        "cursor-wait opacity-50": status === "fetching",
        "cursor-not-allowed opacity-50": status === "disabled",
      }
    )}
    disabled={status}
  >
    {children}
  </button>
);

export default Button;
