import Image from "next/image";
import { useTranslation } from "react-i18next";

import TargetMap from "assets/submitapp-target-map.png";

const WhereDoWeTarget = () => {
  const { t } = useTranslation();

  return (
    <main className="bg-whatWeOfferBackground">
      <section className="mx-auto flex max-w-screen-xl flex-col gap-8 px-10 py-14 md:min-h-[85vh] md:flex-row md:items-center md:justify-center md:gap-10 md:py-0 ">
        <div className="mx-auto flex w-full flex-col justify-center gap-6 text-center md:w-1/3  ">
          <h1 className="text-center text-4xl font-semibold  text-secondary-1000  md:text-6xl">
            {t("whereDoWeTarget.title")}
          </h1>
          <p className="text-base text-black sm:text-xl">
            {t("whereDoWeTarget.description")}
          </p>
        </div>
        <Image
          src={TargetMap}
          alt="Submitapp's targetted regions on map"
          className="md:w-2/3"
        />
      </section>
    </main>
  );
};

export default WhereDoWeTarget;
